import { Divider } from '@chakra-ui/react';
import { Filter } from "../Filter";

export const ColumnHeader = props => {
	const {
		name,
		label,
		filter = null,
		active = '',
		onFilter,
	} = props;

	return (
		<>
			<h2>{ label ?? name }</h2>
			<Divider marginY={1}/>
			{ filter &&
				<Filter
					{ ...filter }
					placeholder="--"
					value={ active ?? '' }
					onChange={ onFilter }
				/>
			}
		</>
	)
}
