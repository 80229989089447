import React, { useState } from 'react';
import { Box, Image } from '@chakra-ui/react';

export const ImageBox = props => {

	const [ hover, setHover ] = useState(false);

	const {
		header,
		image,
		onClick,
	} = props;

	return (
		<Box
			position="relative"
			flex={1}
			onClick={ onClick }
			cursor={ ( onClick ) ? 'pointer' : '' }
			opacity={ ( onClick ) ? 1 : 0.5 }
			transition={ 'transform .2s' }
			transform={ ( onClick && hover ) ? 'scale(1.05)' : '' }
			onMouseEnter={() => setHover(true)}
			onMouseOut={() => setHover(false)}
		>
			<Image src={ image } width="100%" aspectRatio={1} objectFit={'cover'} />
			<Box backgroundColor={ 'brand.500' } position="absolute" top={0} left={0} color="#fff" padding={3} paddingY={1}>
				{ header }
			</Box>
		</Box>
	)
};
