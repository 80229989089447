import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import { ColumnHeader } from "./ColumnHeader";

export const FilterTable = props => {
	const {
		columns,
		filters,
		active,
		data,
		onFilter,
		onSort,
	} = props;

	const filterObjects = {};
	for ( let i = 0; i < filters.length; i++ ) {
		filterObjects[ filters[ i ].key ] = filters[ i ];
	}

	const columnObjects = {};
	for ( let i = 0; i < columns.length; i++ ) {
		columnObjects[ columns[ i ].key ] = columns[ i ];
	}

	return (
		<TableContainer fontSize="lg" width="100%">
			<Table>
				<Thead>
					<Tr>
						{
							columns.map( ( column, index ) => {
								return <Th key={ index } borderColor="white" verticalAlign="top">
									<ColumnHeader
										name={ column.key }
										label={ column.label }
										active={ active[ column.key ] ?? null }
										filter={ filterObjects[ column.key ] ?? {} }
										onFilter={ ( event ) => {
											const value = event.target.value;
											if ( ! active[ column.key ] || value !== active[ column.key ] ) {
												active[ column.key ] = value;
												onFilter( active );
											}
										} }
									/>
								</Th>
							} )
						}
					</Tr>
				</Thead>
				<Tbody>
					{
						data.map( ( row, index ) => {
							return <Tr key={index}>
								{
									Object.keys( row ).map( ( key, index ) => {
										let style = {};
										let value = row[ key ];

										if ( row.hasOwnProperty( 'voldoet' ) ) {
											const valid = !!parseInt( row.voldoet, 10 );
											if ( 'voldoet' === key ) {
												style = {
													background: ( valid ) ? '#00800090' : '#ffa50090'
												}
											} else {
												style = {
													background: ( valid ) ? '#00800050' : '#ffa50020'
												}
											}
										}

										if ( columnObjects[ key ].hasOwnProperty( 'map' ) ) {
											value = columnObjects[ key ].map [ value ] ?? value;
										}

										return <Td style={ style } key={ index } borderColor="white">{ value }</Td>
									} )
								}
							</Tr>
						} )
					}
				</Tbody>
			</Table>
		</TableContainer>
	)
}
