import { HStack, StackDivider } from "@chakra-ui/react";
import { Filter } from "../Filter";

export const Filters = props => {
	const {
		filters = [],
		active = {},
		onChange,
	} = props;

	return (
		<HStack divider={<StackDivider borderColor='gray.200' />}>
			{
				filters.map( ( filter, index ) => {
					const selected = active.hasOwnProperty( filter.key ) ? active[ filter.key ] : false;
					return (
						<Filter
							{ ...filter }
							key={ index }
							value={ selected }
							onChange={ ( event ) => {
									let value = event.target.value;
									if ( value !== selected ) {
										active[ filter.key ] = value;
										onChange( active );
									}
								} }
						/>
					)
				} )
			}
		</HStack>
	)
}
