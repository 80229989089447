import React from 'react';
import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
  //defineStyle,
  defineStyleConfig,
  //theme as baseTheme,
} from '@chakra-ui/react';
import Layout from "./Controllers/Layout";

const customTheme = extendTheme(
    {
      colors: {
        brand: {
            100: '#004e8f',
            200: '#004e8f',
            300: '#004e8f',
            400: '#004e8f',
            500: '#004e8f',
            600: '#00325c',
            700: '#00325c',
            800: '#00325c',
            900: '#00325c',
        },
      },
      components: {
          Heading: defineStyleConfig({
              baseStyle: {
                  fontWeight: 'normal',
              }
          }),
          Button: {
              baseStyle: {
                  rounded: 'none',
                  color: 'white'
              }
          },
          Card: {
              baseStyle: {
                  rounded: 'none',
                  borderRadius: '0',
                  border: '1px solid'
              }
          },
      }
    },
    withDefaultColorScheme( { colorScheme: 'brand' } ),
);

function App() {
  return (
    <ChakraProvider theme={customTheme}>
        <Layout />
    </ChakraProvider>
  );
}

export default App;
