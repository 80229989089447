import { Image, Select, HStack, InputGroup, InputRightAddon } from '@chakra-ui/react';
import { Popover, PopoverTrigger, Portal, PopoverContent, PopoverCloseButton, PopoverBody } from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/all";

export const Filter = props => {
	const {
		label,
		name: name = '',
		options: options = [],
		placeholder: placeholder = '-- ' + ( label ?? name ) + ' --',
		tooltip,
		value = '',
		onChange,
	} = props;

	if ( ! options || 1 === options.length ) {
		return;
	}

	return (
		<HStack>
			<InputGroup>
				<Select
					placeholder={ placeholder }
					onChange={ onChange }
					value={ value }
				>
					{
						options.map( ( option, index ) => {
							let label = option, value = option;

							if ( 'object' === typeof option ) {
								value = option.value ?? '';
								label = option.label ?? '';
							}

							return <option value={ value } key={ index }>{ label }</option>
						} )
					}
				</Select>
			{ tooltip &&
				<Popover>
					<PopoverTrigger>
						<InputRightAddon paddingX={3}>
							<FaInfoCircle />
						</InputRightAddon>
					</PopoverTrigger>
					<Portal>
						<PopoverContent>
							<PopoverCloseButton />
							<PopoverBody>
								{ tooltip.text }
								{ tooltip.img &&
									<Image src={ tooltip.img }></Image>
								}
							</PopoverBody>
						</PopoverContent>
					</Portal>
				</Popover>
			}
			</InputGroup>
		</HStack>
	)
}
