import React from "react";
import { Button, HStack, useColorModeValue } from '@chakra-ui/react';
import { Logo } from "../Logo";
import { ColorSwitcher } from "../ColorSwitcher";
import { MdArrowBack } from 'react-icons/all';

export const Header = props => {
	const bg = useColorModeValue( 'white', 'black' );
	const text = useColorModeValue( 'brand', 'white' );
	return (
		<HStack p={3} spacing={3} w="100%" justifyContent="space-between" backgroundColor={ bg } boxShadow="md" position="sticky" top={0} zIndex={1}>
			<Logo pointerEvents="none" />
			{ props.onReturn &&
			  <Button variant='ghost' onClick={ props.onReturn } color={ text }><MdArrowBack /> Terug</Button>
			}
			<ColorSwitcher justifySelf="flex-end" />
		</HStack>
	)
};
