
function loadColumns( columns, data ) {
	let dataColumns = loadColumnsFromData( data );

	for ( let i = 0; i < dataColumns.length; i++ ) {
		const key = dataColumns[ i ];
		if ( columns.hasOwnProperty( dataColumns[ i ] ) ) {
			columns[ dataColumns[ i ] ].key = key;
			dataColumns[ i ] = { ...columns[ dataColumns[ i ] ] };
		} else {
			dataColumns[ i ] = {
				key: key,
				label: key.charAt(0).toUpperCase() + key.slice(1),
			}
		}
	}

	return dataColumns;
}

function loadFilters( columns, data ) {
	let dataFilters = loadFiltersFromData( data, columns );

	return dataFilters.map( ( filter ) => {
		if ( columns.hasOwnProperty( filter.key ) ) {
			if ( columns[ filter.key ].hasOwnProperty( 'map' ) ) {
				filter.options = filter.options.map( ( value ) => {
					return {
						value: value,
						label: columns[ filter.key ].map[ value ] ?? value,
					};
				} );
			}
			if ( columns[ filter.key ].hasOwnProperty( 'order' ) ) {
				filter.options = sortData( filter.options, 'value' );
				if ( ( 'DESC' === columns[ filter.key ].order ) ) {
					filter.options = filter.options.reverse();
				}
			}
		}
		return filter;
	} );
}

function loadColumnsFromData( data ) {
	let columns = {};

	for ( let i=0; i < 1; i++) {
		let row = { ...data[ i ] };

		Object.keys( row ).forEach( ( key ) => {
			if ( ! columns.hasOwnProperty( key ) ) {
				columns[ key ] = true;
			}
		} );
	}

	return Object.keys( columns );
}

function loadFiltersFromData( data, columns ) {
	let filters = {}, order = [];

	for ( let i=0; i < data.length; i++ ) {
		let row = { ...data[ i ] };

		Object.keys( row ).forEach( ( key ) => {

			if ( ! filters.hasOwnProperty( key ) ) {
				filters[ key ] = {
					...columns[ key ],
					options: [],
				};
				order.push( key );
			}

			if ( ! filters[ key ].options.includes( row[ key ] ) ) {
				filters[ key ].options.push( row[ key ] );
			}

		} );
	}

	return order.map( ( key ) => {
		return filters[ key ];
	} );
}

function loadSortFromData( data ) {
	let sort = [];
	let row = data[0];

	for ( const column in row ) {
		if ( ! row.hasOwnProperty( column ) ) {
			continue;
		}
		if ( row[ column ].match( /^[0-9.,]*$/g ) ) {
			sort.push( { name: column, primer: parseFloat, reverse: true } );
			continue;
		}
		sort.push( column );
	}

	return sort;
}

function filterData( data, filters ) {
	if ( ! data || ! filters ) {
		return data;
	}

	return data.filter( ( value ) => {

		for ( const key in filters ) {
			if ( filters.hasOwnProperty( key ) && filters[ key ] ) {
				// Row has key of the selected filter.
				if ( ! value.hasOwnProperty( key ) ) {
					return false;
				}
				// Row value doesn't mach filter.
				if ( value[ key ] != filters[ key ] ) {
					return false;
				}
			}
		}

		return value;

	} );
}

function getCmpFunc(primer, reverse) {
	if ( 'default' === primer ) {
		return function( a, b ) {
			if ( a == b ) return 0;
			return a < b ? -1 : 1;
		};
	}
	var dfc = getCmpFunc( 'default' ), // closer in scope
		cmp = getCmpFunc( 'default' );
	if (primer) {
		cmp = function(a, b) {
			return dfc(primer(a), primer(b));
		};
	}
	if (reverse) {
		return function(a, b) {
			return -1 * cmp(a, b);
		};
	}
	return cmp;
};

function sortBy() {
	var fields = [],
		n_fields = arguments.length,
		field, name, reverse, cmp;

	// preprocess sorting options
	for (var i = 0; i < n_fields; i++) {
		field = arguments[i];
		if ( 'string' === typeof field ) {
			name = field;
			cmp = getCmpFunc( 'default' );
		}
		else {
			name = field.name;
			cmp = getCmpFunc( field.primer, field.reverse );
		}
		fields.push({
			name: name,
			cmp: cmp
		});
	}

	// final comparison function
	return function(A, B) {
		var name, result;
		for (var i = 0; i < n_fields; i++) {
			result = 0;
			field = fields[i];
			name = field.name;

			result = field.cmp(A[name], B[name]);
			if (result !== 0) break;
		}
		return result;
	}
}

function sortData( data, sort ) {
	if ( ! sort || ! sort.length ) {
		sort = loadSortFromData( data );
	}
	return data.sort( sortBy( sort ) );
}

export {
	loadColumns,
	loadFilters,
	loadColumnsFromData,
	loadFiltersFromData,
	loadSortFromData,
	filterData,
	sortData,
};
