import React from 'react';
import {
	Button, Image,
	Flex, Heading, List, ListIcon, ListItem, Box,
} from '@chakra-ui/react';
import { CardContainer } from '../Components/CardContainer';
import { MdCheckCircle } from 'react-icons/all';

export default function Index( props ) {

	const {
		page,
		callbacks
	} = props;

	return (
		<Flex gap={8} direction={{ base: 'column-reverse', md: 'row' }}>
			<CardContainer
				atts={ { maxWidth: { base: 'none', md: "450px" }, flexGrow: 1 } }
				header={ <Heading size="xl">{ page.header }</Heading> }
				body={
					<>
						{ page.title &&
						  <Heading size="lg">{ page.title }</Heading>
						}
						{ page.text }
						{ page.list &&
						  <List gap={2} marginTop={ 6 }>
							  { page.list.map( ( item, index ) => {
								  return <ListItem key={ index }>
									  <ListIcon as={MdCheckCircle} color='brand.500' />
									  { item }
								  </ListItem>
							  } ) }
						  </List>
						}
					</>
				}
				footer={ page.actions &&
					<>
						{ page.actions.map( ( item, index ) => {
							return <Button key={ index } minWidth="50%" color={'white'} onClick={ callbacks[ item.action ] }>{ item.text }</Button>
						} ) }
					</>
				}
			/>
			{ page.image &&
			  <Box>
				  <Image src={ page.image } width='auto' />
			  </Box>
			}
		</Flex>
	)
}
