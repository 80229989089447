import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { CardContainer } from '../Components/CardContainer';
import { ImageBox } from '../Components/ImageBox';

export default function TypeSelect( props ) {

	const {
		page,
		types,
		callbacks
	} = props;

	return (
		<Flex gap={8} direction='column'>
			<CardContainer
				direction={ 'horizontal' }
				header={ <Heading size="xl" minW={ 300 }>{ page.header }</Heading> }
			/>
			{ types &&
			  <Flex gap={6} direction={{ base: 'column', sm: 'row' }}>
				  { types.map( ( item ) => {
					  return <ImageBox key={ item.type } onClick={ ( ! item.disabled ) ? () => { callbacks.selectType( item.type ) } : null } image={ item.image } header={ item.title } />
				  } ) }
			  </Flex>
			}
		</Flex>
	)
}
