import React, { useState } from 'react';
import { HStack, VStack, Alert } from "@chakra-ui/react";
import { Filters } from "../Components/Filters";
import { filterData, sortData, loadColumns, loadFilters } from "../Util/data";
import { FilterTable } from "../Components/FilterTable";

export default function FilterControl( props ) {
	const [ active, setActive ] = useState( {} );
	const [ sort, setSort ] = useState( props.sort ?? [] );

	const {
		config,
		data,
	} = props;

	const columns = loadColumns( config.columns, data );
	const filters = loadFilters( config.columns, data );
	const filteredData = sortData( filterData( data, active ), sort );

	const updateFilters = function ( activeFilters ) {
		setActive( Object.assign( {}, activeFilters ) );
	}

	return (
		<VStack flexGrow={1}>
			{ filteredData.length < 100 &&
				<FilterTable
					data={ filteredData }
					columns={ columns }
					filters={ filters }
					active={ active }
					onFilter={ updateFilters }
					onSort={ null }
				/>
			}
			{ filteredData.length >= 100 &&
				<>
					<HStack>
						<Filters
							filters={ filters }
							active={ active }
							onChange={ updateFilters }
						/>
					</HStack>
					<Alert status={ 'success' }>Data geladen, { filteredData.length } resultaten gevonden, verfijn uw selectie om resultaten te tonen</Alert>
				</>
			}
		</VStack>
	)
}
