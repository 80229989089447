import React, { useEffect, useState } from 'react';
import { Heading, HStack, Spinner, Flex, Image, Button } from '@chakra-ui/react';
import FilterControl from "../Controllers/FilterControl";
import { loadConfig, loadData } from '../Util/loader';
import { CardContainer } from '../Components/CardContainer';

export default function Blauwdruk( props ) {

	const {
		typeInfo,
		type
	} = props;

	const [ data, setData ] = useState(0);
	const [ config, setConfig ] = useState(null);

	const initConfig = async () => {
		setConfig( await loadConfig( '/config-' + type + '.json' ) );
	}

	const initData = async () => {
		setData( await loadData( '/dataset-' + type + '.csv' ) );
	}

	useEffect( () => {
		if ( config ) {
			initData();
		} else {
			initConfig();
		}
	}, [ config ] );

	return (
		<Flex gap={8} direction='column' flexGrow={1}>
			<CardContainer
				direction={ 'horizontal' }
				atts={ { position: 'relative', paddingY: 0 } }
				header={ <Heading size="xl">{ typeInfo.title }</Heading> }
				headerAtts={ { marginY: 6 } }
				body={ <Button color={'white'}>Download PDF</Button> }
				bodyAtts={ { display: 'flex', alignItems: 'center' } }
				footer={ <Image src={ typeInfo.image } height="100%" aspectRatio={1} objectFit={'cover'} /> }
				footerAtts={ { padding: 0, width: 150 } }
			/>
			<HStack flexGrow={1}>
				{ ! data &&
				  <Spinner thickness='4px' color='brand.500' size='xl' />
				}
				{ data &&
				  <FilterControl
					  data={ data }
					  config={ config }
					  reload={ initData }
				  />
				}
			</HStack>
		</Flex>
	)
}
