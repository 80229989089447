import React from "react";
import { Box, Card, CardBody, CardFooter, CardHeader, Divider, StackDivider } from '@chakra-ui/react';

export const CardContainer = props => {

	const {
		header,
		body,
		footer,
		variant = 'elevated',
		direction = 'column',
		atts = {},
		headerAtts = {},
		bodyAtts = {},
		footerAtts = {},
	} = props;

	const vertical =  ( 'column' === direction );

	return (
		<Card
			direction={ direction }
			variant={ variant }
			justifyContent={ vertical ? 'center' : 'space-between' }
			alignItems={ vertical ? '' : 'center' }
			minH={150}
			borderRadius={0}
			borderWidth={1}
			borderColor="gray.200"
			{...atts}
		>
			{ header &&
			  <CardHeader marginY={6} paddingX={8} flexGrow={0} position="relative" {...headerAtts}>
				  <Box height="100%" width={ 4 } backgroundColor={ 'brand.500' } marginLeft={ '-10' } position="absolute" top={0} />
				  { header }
			  </CardHeader>
			}
			{ ( body || footer ) && (
				vertical ?
					<Divider width="80%" marginX="auto" orientation={ 'horizontal' } />
					:
					<StackDivider marginY={6} borderWidth={1} borderColor='gray.200' />
			) }
			{ body &&
			  <CardBody paddingX={8} flexGrow={ ( vertical ) ? 0 : 1 } {...bodyAtts}>
				  { body }
			  </CardBody>
			}
			{ footer &&
			  <CardFooter paddingX={8} flexGrow={0} justifyContent={ 'center' } {...footerAtts}>
				  { footer }
			  </CardFooter>
			}
		</Card>
	)
};
